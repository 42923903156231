import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col, Card } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query ImgQuery {
      file(relativePath: { eq: "P1044778.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About" />
      <Container fluid>
        <Row className="justify-content-center text-white">
          <Col className="mt-4" md={6} xl={4}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Collin and Ryan"
              className="rounder"
            />
          </Col>
          <Col md={6} xl={4}>
            <Card className="mt-4 bg-dark">
              <Card.Body>
                <p>
                  <span className="font-weight-bold">REFLEKT</span> is a nomadic
                  production house developing work that entertains while
                  examining what it means to be human.
                </p>
                <p>
                  Constantly striving to expand our production capabilities,
                  we’ve developed a diverse filmography that includes dramatic,
                  comedic, avant-garde, and documentary work.
                </p>
                <p>
                  <span className="font-weight-bold">REFLEKT</span> is committed
                  to bold aesthetic risk-taking. Moving forward, we look to push
                  the limits of our established mediums to tell stories that
                  matter, with the potential for investigating commercial and
                  music-video production as well.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center text-white">
          <Col md={6} xl={4}>
            <Card className="bg-dark mt-4">
              <Card.Body>
                <p>
                  <p className="font-weight-bold">Collin Colaizzi</p>
                  Colaizzi is a film student at New York University.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={4}>
            <Card className="bg-dark mt-4">
              <Card.Body>
                <p>
                  <p className="font-weight-bold">Ryan Shea</p>
                  Ryan Shea is a student at Yale University.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
